// Signup

.loginform-card-form {
	.upload__image-wrapper {
		margin-top: 10px;
		position: relative;

		.close {
			color: $red;
			position: absolute;
			top: -4px;
			left: 36px;
		}
	}
}
