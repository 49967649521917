//POSTS STYLES

.posts {
	position: relative;
	.add-button {
		z-index: 1;
		position: absolute;
		top: 10px;
		left: 10px;

		.button {
			color: $white;
			background: $nt_default;
			text-transform: none;
		}
	}
	.image {
		height: 100px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 4px;
	}
}

// ADD POSTS

.addposts {
	margin-top: 30px;

	.button-pri {
		color: $white;
		background: $nt_default;
		text-transform: none;

		&:hover {
			background: $nt_default;
		}
	}

	.button-sec {
		text-transform: none;
	}
}
