//BOOK STYLES

.books {
	position: relative;
	.add-button {
		z-index: 1;
		position: absolute;
		top: 10px;
		left: 10px;

		.button {
			color: $white;
			background: $nt_default;
			text-transform: none;
		}
	}
	.image {
		width: 60px;
		height: 100px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 4px;
	}
}

// ADD BOOKS

.addbooks {
	margin-top: 30px;

	.input-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;

		&.three {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	.button-container {
		margin-top: 20px;
		width: 300px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
	}

	.button-pri {
		color: $white;
		background: $nt_default;
		text-transform: none;

		&:hover {
			background: $nt_default;
		}
	}

	.button-sec {
		text-transform: none;
	}
}
