.loginpage {
	&-container {
		.loginpage {
			.logo {
				@include responsive-for(md) {
					display: flex;
					justify-content: center;
				}

				img {
					margin-top: 50px;
					width: 150px;
					position: absolute;
					object-fit: contain;
					align-self: flex-start;
					margin-left: 2vw;

					@include responsive-for(md) {
						width: 150px;
						position: relative;
						padding: 10px;
						margin-left: 0;
					}
				}
			}

			&-desktop {
				padding: 50px;
				padding-bottom: 120px;
				height: 100vh;
				display: flex;
				justify-content: center;
				align-items: flex-end;

				@include responsive-for(md) {
					overflow-y: auto;
					height: auto;
				}

				.illustrator {
					width: 60vw;
					padding-top: 80px;

					img {
						width: 60%;
					}

					@include responsive-for(md) {
						display: none;
					}
				}

				.loginform {
					width: 40vw;
					display: flex;
					justify-content: center;

					&-card {
						padding-left: 12px;
						padding-right: 12px;
						display: grid;
						justify-items: center;
						align-items: center;

						&-title {
							width: 100%;
							// font-family: $robotobold;
							font-size: $small;
							color: $black;
						}

						&-form {
							margin-top: 20px;

							.button {
								text-transform: none;
								color: $white;
								margin-top: 20px;
								background: $nt_default;
								// font-family: $robotobold;
								font-size: larger;
							}
						}

						&-register {
							// font-family: $robotolight;
							margin-top: 20px;

							span {
								// font-family: $robotobold;
								color: $nt_default;
								cursor: pointer;
							}
						}

						&-goto {
							// font-family: $robotolight;
							margin-top: 20px;

							span {
								// font-family: $robotobold;
								// color: $light_black;
								cursor: pointer;
							}
						}
					}

					@include responsive-for(md) {
						width: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						overflow-y: auto;
					}
				}
			}
		}
	}
}
