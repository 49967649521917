// BUTTON STYLES

.button {
	outline: none;
	border: none;
	padding: 7px 18px;
	border-radius: 4px;
	font-size: $extrathin;
	cursor: pointer;
	box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.16);

	&.default {
		background: white;
		color: $nt_default;
		border: 2px solid $nt_default;
		transition: background 0.4s, border-color 0.8s;

		&:hover {
			background: $nt_default;
			color: white;
		}

		&:active {
			background: darken($nt_default, 10%);
		}
	}

	&.default-invert {
		background: $nt_default;
		color: white;
		border: 2px solid white;
		transition: background 0.4s, border-color 0.8s;

		&:hover {
			background: white;
			color: $nt_default;
		}

		&:active {
			background: darken(white, 10%);
			border-color: darken(white, 10%);
		}
	}

	&.inverted {
		background: $nt_default;
		color: white;
		border: 2px solid $nt_default;
		transition: background 0.4s, border-color 0.8s;

		&:hover {
			background: darken($nt_default, 4%);
			border-color: darken($nt_default, 4%);
		}

		&:active {
			background: darken($nt_default, 10%);
			border-color: darken($nt_default, 10%);
		}
	}

	&.floating {
		background: $white;
		color: black;
		box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.16);
		padding: 7px 20px;
		cursor: pointer;
		transition: background 0.4s, border-color 0.8s;
		border: 3px solid white;

		&:hover {
			background: darken($white, 4%);
			border-color: darken($white, 4%);
		}

		&:active {
			background: darken($white, 10%);
			border-color: darken($white, 10%);
		}
	}
}
