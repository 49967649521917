//VIDEO STYLES

.videos {
	position: relative;
	.add-button {
		z-index: 1;
		position: absolute;
		top: 10px;
		left: 10px;

		.button {
			color: $white;
			background: $nt_default;
			text-transform: none;
		}
	}
	.image {
		height: 100px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 4px;
	}
}
