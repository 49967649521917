.dropdown-menu {
    background-color: $white;
    padding: 6px 0px;
    list-style-type: none;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.12);
    border: 1px solid #F8F8F8;
    border-radius: 10px;
  
    &-separator {
      width: 100%;
      border-bottom: 1px solid #F8F8F8;
      margin: 5px 0px;
    }
  
    &-item {
      padding: 10px 20px;
      font-family: $avertaSemiBold;
      cursor: pointer;
      color: $defaulttextcolor;
  
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #F8F8F8;
      }
  
      &.danger {
        color: $orange;
      }
    }
  }
